import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Navbar, Nav, Button, NavDropdown } from "react-bootstrap"
import { useAuth } from "react-use-auth"
import { FaUser, FaBars } from "react-icons/fa"
import { GoSignOut } from "react-icons/go"
import { navigate } from "@reach/router"
import firebase from "gatsby-plugin-firebase";

const Login = () => {
  const { isAuthenticated, login, user } = useAuth();
  const handleLogout = (event) => {
    const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;
    const auth0Home = process.env.GATSBY_AUTH0_HOME;
    const redirectUrl = encodeURI(auth0Home);
    const auth0ClientId = process.env.GATSBY_AUTH0_CLIENTID;
    const logoutUrl = `https://${auth0Domain}/v2/logout?returnTo=${redirectUrl}&client_id=${auth0ClientId}`;
    localStorage.removeItem('useAuth:user');
    firebase.auth().signOut().catch(err => console.log(err));
    navigate(logoutUrl);
  }
  const handleLogin = (event) => {
    login();
  };

  if (isAuthenticated()) {
      return (
        <>
          <NavDropdown title={user.name || "Welcome"} id="basic-nav-dropdown" alignRight>
            <NavDropdown.Item as={Link} to="/account">Account</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}><GoSignOut style={{marginRight: "3px"}}/>Log Out
            </NavDropdown.Item>
          </NavDropdown>      
        </>
      );
  } else {
      return (
        <>
          <Button variant="light" onClick={handleLogin}><FaUser style={{ paddingTop: "2px", marginRight:"3px", verticalAlign: "baseline"}} />Member Login</Button>
        </>
      );
  }
};

const CustomNavbar = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Official-Chapter-Seal.png" }) {
        childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 30, height: 30) {
            ...GatsbyImageSharpFixed
            }
        }
      }
    }
  `)
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">
            <Img 
              fixed={data.file.childImageSharp.fixed} 
              className="d-inline-block align-top"
            />
            {' Sacramento'}
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav"><FaBars /> </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/events" className="nav-link" activeClassName="active">
                Events & Meetings
            </Link>
            <Link to="/sponsorship" className="nav-link" activeClassName="active">
                Sponsorship
            </Link>
            <Link to="/about" className="nav-link" activeClassName="active">
                About
            </Link>
          </Nav>
          <Nav>
            <Login />
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
