/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"

import React from "react";
import { navigate } from "gatsby";
import { AuthProvider } from "react-use-auth";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
 
export const wrapRootElement = ({ element }) => (
    <AuthProvider
        navigate={navigate}
        auth0_domain={`${process.env.GATSBY_AUTH0_DOMAIN}`}
        auth0_client_id={`${process.env.GATSBY_AUTH0_CLIENTID}`}
        auth0_params={{scope: "openid profile email update:current_user_metadata"}}
    >
        {element}
    </AuthProvider>
);