/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

import Navbar from "./navBar"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
    query {
      file(relativePath: { eq: "Chapter-Masthead-standard_1026x250.png" }) {
        childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid (maxWidth: 1026, maxHeight: 250) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
  `}
    render={data => (
      <>
        <Container fluid className="px-0 main">

          <Navbar />
          
          <Row noGutters>
            <Col>
              <Container className="mt-0">
              <Img loading="eager" fluid={data.file.childImageSharp.fluid} />
              </Container>
            </Col>
          </Row>
          
          <Row noGutters>
            <Col>
              <Container className="mt-3">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <span>
                  © {new Date().getFullYear()}
                  {` `}
                  International Information Systems Security Certification Consortium, Inc. (ISC)², in website format and trade dress only. All Rights Reserved. (ISC)², CISSP, ISSAP, ISSEP, ISSMP, CSSLP, CAP, CCFP, CCSP, HCISPP, SSCP and CBK are registered certification, service, and trademarks of (ISC)². Disclaimer: (ISC)² does not own, operate, or moderate this website. All content of this site, exclusive of licensed trademarks or copyright, is property of the designated (ISC)² Chapter organization, which is not owned, managed, or controlled by (ISC)² and operates independent of (ISC)².
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
